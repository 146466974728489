import React, { useEffect } from "react"
import * as styles from "./global.module.scss"
import fagure from "../images/fagure6.mp4"

export default function Loading() {
  useEffect(() => {
    Object.defineProperty(HTMLMediaElement.prototype, "playing", {
      get: function () {
        return !!(
          this.currentTime > 0 &&
          !this.paused &&
          !this.ended &&
          this.readyState > 2
        )
      },
    })

    setTimeout(() => {
      const video = document.getElementById("loading_video")
      if (!video.playing) {
        window.location.assign("home")
      }
    }, 3000)
  })

  return (
    <div>
      <div className={styles.loading_background}>
        <div className={styles.transparent_text}>
          <p className={styles.p1}>.</p>
          <p className={styles.p2}>.</p>
          <p className={styles.p3}>.</p>
          <p className={styles.p4}>.</p>
          <p className={styles.p5}>.</p>
          <p className={styles.p6}>.</p>
          <p className={styles.p7}>.</p>
          <p className={styles.p8}>.</p>
          <p className={styles.p9}>.</p>
        </div>
        <video
          id="loading_video"
          playsInline={true}
          autoPlay={true}
          muted={true}
          className={styles.loading_video}
          onEnded={() => {
            window.location.assign("home")
          }}
        >
          <source src={fagure} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
