import React from "react"
import { Helmet } from "react-helmet"
import * as styles from "./global.module.scss"
import Loading from "./loading"
import GoogleTagManager from "../components/GoogleTagManager/GoogleTagManager"

export default function Home() {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competdac - Home</title>
        <meta
          name="description"
          content="A summary of Competdac, in images"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <Loading />
      </div>
    </div>
  )
}
