// extracted by mini-css-extract-plugin
export var container = "global-module--container--1JZYG";
export var portfolioHomepage = "global-module--portfolioHomepage--1-huZ";
export var loading_video = "global-module--loading_video--mHblA";
export var loading_background = "global-module--loading_background--1E9QT";
export var transparent_text = "global-module--transparent_text--3cA7m";
export var p1 = "global-module--p1--3Ras_";
export var p2 = "global-module--p2--3LI5c";
export var p3 = "global-module--p3--33aRD";
export var p4 = "global-module--p4--1BpDm";
export var p5 = "global-module--p5--1ku6f";
export var p6 = "global-module--p6--PUo6A";
export var p7 = "global-module--p7--1NpmF";
export var p8 = "global-module--p8--3LiVL";
export var p9 = "global-module--p9--2RzbB";
export var entry_content = "global-module--entry_content--1rQs9";