import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const GoogleTagManager = () => {
  useEffect(() => {
    var script = document.createElement("script")
    script.type = "text/javascript"

    var code = `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-FXZKH910CQ');`

    script.appendChild(document.createTextNode(code))
    document.head.appendChild(script)
  }, [])

  return (
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-FXZKH910CQ"
      ></script>
    </Helmet>
  )
}

export default GoogleTagManager
